import React, { useEffect } from "react";
import "./Product.css";
import {
  BsBook,
  BsGrid,
  BsHandThumbsUpFill,
  BsNewspaper,
} from "react-icons/bs";
import { Link } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataKatalog } from "../../../../Config/redux/action";
import { Col, Row } from "react-bootstrap";

const Product = ({ loginInfo }) => {
  const dispatch = useDispatch();
  const { dataKegiatan } = useSelector((state) => state);

  useEffect(() => {
    dispatch(setDataKatalog());
  }, [dispatch]);

  return (
    <section id="product" className="pt-5">
      <div className=" d-flex flex-column justify-content-center align-items-center mt-5">
        <h2 className="font-viga mb-4">Our Product</h2>
        <Row className=" d-flex justify-content-around w-100">
          <Col md={4} className="d-flex justify-content-center mb-0">
            <Link
              to={loginInfo.length !== 0 ? "/kastrat-admin" : "/kastrat"}
              className="text-decoration-none"
            >
              <div className="card-product hover-card">
                <BsBook color="#DAC0A3" size={44} />
                <h3 className="font-viga m-0 my-2">Isu Kastrat</h3>
                <p className="font-voces text-justify">
                  Informasi mengenai isu-isu ekonomi terupdate.
                </p>
              </div>
            </Link>
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-0">
            <Link
              className={`text-decoration-none`}
              to={
                loginInfo.length !== 0
                  ? "/kegiatan-admin"
                  : dataKegiatan?.length !== 0 && loginInfo.length === 0
                  ? "/kegiatan"
                  : "/"
              }
            >
              <div
                className={`card-product ${
                  dataKegiatan?.length !== 0 && "hover-card"
                }`}
              >
                <BsNewspaper color="#DAC0A3" size={44} />
                <h3 className="font-viga m-0 my-2">ECOSTO</h3>
                {dataKegiatan?.length !== 0 ? (
                  <p className="font-voces text-justify">
                    Berisi segala informasi dan kegiatan SMF periode 2023.
                  </p>
                ) : (
                  <p className="font-voces text-center">Coming Soon....</p>
                )}
              </div>
            </Link>
          </Col>
          <Col md={4} className="d-flex justify-content-center mb-0">
            <Link to={"/semagrams"} className="text-decoration-none">
              <div className="card-product hover-card">
                <BsGrid color="#DAC0A3" size={44} />
                <h3 className="font-viga m-0 my-2">SEMAGRAMS</h3>
                <p className="font-voces text-justify">
                  Berisi segala kegiatan SMF FEB.
                </p>
              </div>
            </Link>
          </Col>

          <Col md={4} className="d-flex justify-content-center mb-0">
            <Link
              className="text-decoration-none"
              to={"https://forms.gle/ivts9bnUAB5WZPpT7"}
            >
              <div className="card-product hover-card">
                <BsHandThumbsUpFill color="#DAC0A3" size={44} />
                <h3 className="font-viga m-0 my-2">Kritik & Saran</h3>
                <p className="font-voces text-justify">
                  Sampaikan kritik, saran, atau apresiasimu pada SMF 2023.
                </p>
              </div>
            </Link>
          </Col>
        </Row>
      </div>
    </section>
  );
};

export default Product;
