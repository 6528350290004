import React, { useEffect, useState } from "react";
import { Col, Container, Row, Form } from "react-bootstrap";
import HTMLFlipBook from "react-pageflip";
import ReactQuill from "react-quill";
import { useDispatch, useSelector } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";
import "react-quill/dist/quill.snow.css";
import {
  setDataKatalog,
  setDataKatalogById,
  updateDataKatalog,
} from "../../../../../Config/redux/action";

const KegiatanDetail = () => {
  const [form, setForm] = useState({
    author: false,
    title: false,
    desc: false,
  });

  // Get Data By Id
  const dispatch = useDispatch();
  const params = useParams();
  const idDetail = params.id;
  const dataByKegiatanId = useSelector((state) => state.dataByKegiatanId);
  const dataKegiatan = useSelector((state) => state.dataKegiatan);

  useEffect(() => {
    dispatch(setDataKatalogById(idDetail));
  }, [dispatch, idDetail]);

  useEffect(() => {
    dispatch(setDataKatalog());
  }, [dispatch]);

  const shuffleArray = (array) => {
    for (let i = array.length - 1; i > 0; i--) {
      const j = Math.floor(Math.random() * (i + 1));
      [array[i], array[j]] = [array[j], array[i]];
    }
    return array;
  };

  const shuffledArray = shuffleArray(dataKegiatan);
  // End Get Data By Id

  // Quill text editor
  const [desc, setDesc] = useState(dataByKegiatanId.kegiatan_desc);
  const modules = {
    toolbar: [
      [{ header: [1, 2, 3, 4, 5, 6, false] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ size: [] }],
      [{ font: [] }],
      [{ align: ["right", "center", "justify"] }],
      [{ list: "ordered" }, { list: "bullet" }],
      ["link", "image"],
      [{ color: ["red", "#785412"] }],
      [{ background: ["red", "#785412"] }],
    ],
  };
  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];
  // End Quill text editor

  // Update Data
  const date = new Date();

  const [data, setData] = useState({
    title: dataByKegiatanId.kegiatan_title,
    author: dataByKegiatanId.kegiatan_author,
  });
  useEffect(() => {
    setData({
      author: dataByKegiatanId.kegiatan_author,
      title: dataByKegiatanId.kegiatan_title,
      subject: dataByKegiatanId.kegiatan_subject,
    });
    setDesc(dataByKegiatanId.kegiatan_desc);
  }, [dataByKegiatanId]);

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };

  const handleUpdate = async (e) => {
    e.preventDefault();
    dispatch(updateDataKatalog(idDetail, data, desc, date));
    setForm({
      author: false,
      title: false,
      desc: false,
    });
  };
  return (
    <React.Fragment>
      <ToastContainer />
      <Container className="kegiatan-detail-container">
        {[dataByKegiatanId].map((item) => {
          const image = item.kegiatan_image?.split(",");
          return (
            <React.Fragment key={item.kegiatan_id}>
              <div className="d-flex justify-content-center flipbook">
                <HTMLFlipBook width={300} height={500}>
                  {image?.map((images) => (
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/kegiatan/${item.kegiatan_title}/${images}`}
                      alt=""
                      className="img-fluid"
                      key={images.id}
                    />
                  ))}
                </HTMLFlipBook>
              </div>
              <div className="kegiatan-detail-title">
                <div className="title d-flex justify-content-between align-items-center">
                  {form.title ? (
                    <React.Fragment>
                      <Form.Group>
                        <Form.Control
                          type="text"
                          name="title"
                          style={{ width: 500 }}
                          value={data.title}
                          onChange={handleChange}
                        />
                      </Form.Group>
                      <i
                        className="fa-solid fa-check icon-edit ms-2"
                        onClick={handleUpdate}
                      ></i>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <h3 className="text-start m-0">{item.kegiatan_title}</h3>
                      <i
                        className="fa-solid fa-pen-to-square icon-edit"
                        onClick={() => setForm({ title: !form.title })}
                      ></i>
                    </React.Fragment>
                  )}
                </div>
                <div className="author d-flex justify-content-between align-items-center">
                  {form.author ? (
                    <React.Fragment>
                      <div className="d-flex align-items-center">
                        <p className="text-start m-0 me-2">By</p>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            name="author"
                            style={{ width: 500 }}
                            value={data.author}
                            onChange={handleChange}
                          />
                        </Form.Group>
                      </div>
                      <i
                        className="fa-solid fa-check icon-edit ms-2"
                        onClick={handleUpdate}
                      ></i>
                    </React.Fragment>
                  ) : (
                    <React.Fragment>
                      <p className="text-start m-0">
                        By {item.kegiatan_author}
                      </p>
                      <i
                        className="fa-solid fa-pen-to-square icon-edit"
                        onClick={() => setForm({ author: !form.author })}
                      ></i>
                    </React.Fragment>
                  )}
                </div>
                {form.desc ? (
                  <React.Fragment>
                    <div className="desc d-flex justify-content-end mt-3 mb-2">
                      <i
                        className="fa-solid fa-check icon-edit mb-2"
                        onClick={handleUpdate}
                      ></i>
                    </div>
                    <ReactQuill
                      theme="snow"
                      value={desc}
                      onChange={setDesc}
                      modules={modules}
                      formats={formats}
                      style={{ height: "300px" }}
                      placeholder="Write some description here..."
                      className="mb-5"
                    />
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    <div className="desc d-flex justify-content-end mt-3 mb-2">
                      <i
                        className="fa-solid fa-pen-to-square icon-edit"
                        onClick={() => setForm({ desc: !form.desc })}
                      ></i>
                    </div>
                    <div className="ql-editor m-0 p-0 mb-5">
                      <div
                        dangerouslySetInnerHTML={{ __html: item.kegiatan_desc }}
                      />
                    </div>
                  </React.Fragment>
                )}
              </div>
            </React.Fragment>
          );
        })}

        {dataKegiatan.length === 0 ? null : (
          <div className="kegiatan-more-container pb-5">
            <div className="more p-3 m-2">
              <Link to={"/kegiatan"} className=" d-flex align-items-center">
                <h5 className="text-start m-0">Read more from FEB UKSW </h5>
                <i className="fa-solid fa-arrow-right ms-3"></i>
              </Link>
            </div>
            <Row>
              <Col lg={4}>
                <div className="d-flex justify-content-center">
                  {shuffledArray.map((item, index) => {
                    const image = item.kegiatan_image?.split(",");
                    if (index <= 1) {
                      return (
                        <div
                          className="book-container book-container-detail"
                          key={item.id}
                        >
                          <img
                            src={`${process.env.REACT_APP_API_URL}/uploads/kegiatan/${item.kegiatan_title}/${image[0]}`}
                            alt="cover"
                            className="img-fluid book-cover"
                          />
                          <p className="mt-2 book-title">
                            {item.kegiatan_title}
                          </p>
                          <p className="book-desc">by SMF FEB UKSW</p>
                        </div>
                      );
                    }
                  })}
                </div>
              </Col>
              {dataKegiatan.length > 1 ? (
                <Col lg={4}>
                  <div className="d-flex justify-content-center">
                    {shuffledArray.map((item, index) => {
                      const image = item.kegiatan_image?.split(",");
                      if (index > 1 && index <= 3) {
                        return (
                          <div
                            className="book-container book-container-detail"
                            key={item.id}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/uploads/kegiatan/${item.kegiatan_title}/${image[0]}`}
                              alt="cover"
                              className="img-fluid book-cover"
                            />
                            <p className="mt-2 book-title">
                              {item.kegiatan_title}
                            </p>
                            <p className="book-desc">by SMF FEB UKSW</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Col>
              ) : null}
              {dataKegiatan.length > 3 ? (
                <Col lg={4}>
                  <div className="d-flex justify-content-center">
                    {shuffledArray.map((item, index) => {
                      const image = item.kegiatan_image?.split(",");
                      if (index > 3 && index <= 5) {
                        return (
                          <div
                            className="book-container book-container-detail"
                            key={item.id}
                          >
                            <img
                              src={`${process.env.REACT_APP_API_URL}/uploads/kegiatan/${item.kegiatan_title}/${image[0]}`}
                              alt="cover"
                              className="img-fluid book-cover"
                            />
                            <p className="mt-2 book-title">
                              {item.kegiatan_title}
                            </p>
                            <p className="book-desc">by SMF FEB UKSW</p>
                          </div>
                        );
                      }
                    })}
                  </div>
                </Col>
              ) : null}
            </Row>
          </div>
        )}
      </Container>
    </React.Fragment>
  );
};

export default KegiatanDetail;
