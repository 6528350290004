import React, { useEffect, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import smfFamily from "../../../../assets/smf.png";
import { Link, useOutletContext, useParams } from "react-router-dom";
import "./Detail.css";
import { useDispatch, useSelector } from "react-redux";
import {
  deleteKegiatanDepartement,
  getDataKegiatanDepartementById,
  updateKegiatanDepartement,
} from "../../../../Config/redux/action";
import { ToastContainer } from "react-toastify";

const Detail = () => {
  const [login] = useOutletContext();
  const { id, idDepartement, idKegiatanDepartement } = useParams();
  const { dataKegiatanDepartementById } = useSelector((state) => state);
  const dispatch = useDispatch();
  const [edit, setEdit] = useState(false);
  const [formData, setFormData] = useState({
    id_departement: "",
    kegiatan_departement_name: "",
    kegiatan_departement_desc: "",
    tanggal_mulai: "",
    tanggal_berakhir: "",
    link_daftar: "-",
  });

  const formatDateForInput = (isoDate) => {
    const date = new Date(isoDate);
    const year = date.getFullYear();
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Menambahkan nol di depan jika bulan < 10
    const day = String(date.getDate()).padStart(2, "0"); // Menambahkan nol di depan jika tanggal < 10
    return `${year}-${month}-${day}`;
  };

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    dispatch(updateKegiatanDepartement(idKegiatanDepartement, formData));
    setEdit(false);
  };

  const handleDelete = () => {
    dispatch(
      deleteKegiatanDepartement(idKegiatanDepartement, id, idDepartement)
    );
  };

  useEffect(() => {
    dispatch(getDataKegiatanDepartementById(idKegiatanDepartement));
  }, [dispatch, idKegiatanDepartement]);

  useEffect(() => {
    setFormData({
      id_departement: idDepartement,
      kegiatan_departement_name:
        dataKegiatanDepartementById?.kegiatan_departement_name,
      kegiatan_departement_desc:
        dataKegiatanDepartementById?.kegiatan_departement_desc,
      tanggal_mulai: formatDateForInput(
        dataKegiatanDepartementById?.tanggal_mulai
      ),
      tanggal_berakhir: formatDateForInput(
        dataKegiatanDepartementById?.tanggal_berakhir
      ),
      link_daftar: dataKegiatanDepartementById?.link_daftar,
    });
  }, [dataKegiatanDepartementById]);
  return (
    <>
      <ToastContainer />
      {Object.keys(login).length !== 0 && (
        <div className="d-flex justify-content-end">
          <Button
            variant="outline-danger"
            className="shadow-sm mb-4"
            onClick={handleDelete}
          >
            Hapus Data
          </Button>
        </div>
      )}
      <div className="text-justify detail-content">
        {edit ? (
          <>
            <Form onSubmit={handleSubmit}>
              <div className="mb-2">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Name"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Name"
                    name="kegiatan_departement_name"
                    value={formData.kegiatan_departement_name}
                    onChange={handleChange}
                  />
                </FloatingLabel>
                <div className="d-flex align-items-center">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Tanggal Mulai"
                  >
                    <Form.Control
                      type="date"
                      placeholder="Tanggal Mulai"
                      name="tanggal_mulai"
                      value={formData.tanggal_mulai}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                  <h4 className="mb-0 mx-3">-</h4>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Tanggal Berakhir"
                  >
                    <Form.Control
                      type="date"
                      placeholder="Tanggal Berakhir"
                      name="tanggal_berakhir"
                      value={formData.tanggal_berakhir}
                      onChange={handleChange}
                    />
                  </FloatingLabel>
                </div>
              </div>
              <FloatingLabel
                controlId="floatingInput"
                label="Description"
                className="mb-3"
              >
                <Form.Control
                  as="textarea"
                  placeholder="Desciption"
                  style={{ height: "15rem" }}
                  name="kegiatan_departement_desc"
                  value={formData.kegiatan_departement_desc}
                  onChange={handleChange}
                />
              </FloatingLabel>
              <div className="mx-3">
                <Row>
                  {dataKegiatanDepartementById?.img_kegiatan_departement.map(
                    (image) => (
                      <Col
                        md={4}
                        className="d-flex justify-content-center mb-3"
                        key={image.id}
                      >
                        <img
                          src={`${process.env.REACT_APP_API_URL}/uploads/ImageKegiatanDepartement/${image.img_name}`}
                          alt="smf-family"
                          className="img-fluid"
                        />
                      </Col>
                    )
                  )}
                </Row>
              </div>
              <div className="d-flex justify-content-end mt-4 link-daftar">
                <FloatingLabel
                  controlId="floatingInput"
                  label="Link Daftar"
                  className="mb-3"
                >
                  <Form.Control
                    type="text"
                    placeholder="Link Daftar"
                    name="link_daftar"
                    style={{ width: "30rem" }}
                    value={formData.link_daftar}
                    onChange={handleChange}
                  />
                </FloatingLabel>
              </div>
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-success"
                  className="shadow-sm mb-2"
                  type="submit"
                >
                  Simpan
                </Button>
              </div>
            </Form>
          </>
        ) : (
          <>
            {Object.keys(login).length !== 0 && (
              <div className="d-flex justify-content-end">
                <Button
                  variant="outline-success"
                  className="shadow-sm mb-2"
                  onClick={() => setEdit(true)}
                >
                  Edit Informasi
                </Button>
              </div>
            )}
            <div className="mb-2">
              <h2 className="m-0 font-viga text-start">
                {dataKegiatanDepartementById?.kegiatan_departement_name}
              </h2>
              <h4 className="m-0">
                {dataKegiatanDepartementById?.tanggal_mulai} -{" "}
                {dataKegiatanDepartementById?.tanggal_berakhir}
              </h4>
            </div>
            <p className="font-voces">
              {dataKegiatanDepartementById?.kegiatan_departement_desc}
            </p>
            <div className="mx-3">
              <Row>
                {dataKegiatanDepartementById?.img_kegiatan_departement?.map(
                  (image) => (
                    <Col
                      md={4}
                      className="d-flex justify-content-center mb-3"
                      key={image.id}
                    >
                      <img
                        src={`${process.env.REACT_APP_API_URL}/uploads/ImageKegiatanDepartement/${image.img_name}`}
                        alt="smf-family"
                        className="img-fluid kegiatan-departement-image"
                      />
                    </Col>
                  )
                )}
              </Row>
            </div>
            <div className="d-flex justify-content-end mt-4 link-daftar">
              {dataKegiatanDepartementById?.link_daftar != "-" && (
                <Link
                  className="font-viga text-decoration-none"
                  to={dataKegiatanDepartementById?.link_daftar}
                >
                  <h3 className="text-end">Link Pendaftaran</h3>
                </Link>
              )}
            </div>
          </>
        )}
      </div>
    </>
  );
};

export default Detail;
