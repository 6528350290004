import React, { useState, useEffect } from "react";
import pictProfile from "../../../../assets/logo-profile.png";
import { BsInstagram, BsLinkedin, BsWhatsapp } from "react-icons/bs";
import { MdEmail } from "react-icons/md";
import Icon from "../../../components/Icon/Icon";
import { Col, Row } from "react-bootstrap";
import { TypeAnimation } from "react-type-animation";

const Header = () => {
  const [screenWidth, setScreenWidth] = useState(window.innerWidth);

  useEffect(() => {
    const handleResize = () => setScreenWidth(window.innerWidth);
    window.addEventListener("resize", handleResize);
    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <section id="home">
      <Row className="d-flex justify-content-center align-items-center">
        {screenWidth > 768 ? (
          <React.Fragment>
            <Col md={6} className="header-content">
              <div className="desc">
                <TypeAnimation
                  sequence={[
                    "Senat Mahasiswa Fakultas", // Types 'One'
                    1000, // Waits 1s
                    "Fakultas Ekonomika dan Bisnis",
                    2000, // Waits
                    "Universitas Kristen Satya Wacana",
                    2000, // Waits
                  ]}
                  wrapper="h1"
                  cursor={true}
                  repeat={Infinity}
                  className="font-viga text-start"
                  style={{
                    fontSize: "2em",
                    display: "inline-block",
                  }}
                />
                <p className="font-voces text-justify">
                  Untuk mengikuti perkembangan terbaru dan berinteraksi dengan
                  Senat Mahasiswa Fakultas Ekonomika dan Bisnis Universitas
                  Kristen Satya Wacana dalam hal Organization Relations,
                  Project, dan Media Partnership, Anda dapat menghubungi kami
                  melalui kontak berikut: 081384805665.
                </p>
              </div>
              <div className="contact-us">
                <h2 className="font-viga text-start">Contact Us</h2>
                <div className="d-flex">
                  <Icon to={"mailto:smf.feb@student.uksw.edu"}>
                    <MdEmail color="#FFF2D8" size={20} />
                  </Icon>
                  <Icon
                    to={
                      "https://www.linkedin.com/company/senat-mahasiswa-fakultas-feb-uksw/"
                    }
                  >
                    <BsLinkedin color="#FFF2D8" />
                  </Icon>
                  <Icon to={"https://instagram.com/smffebuksw"}>
                    <BsInstagram color="#FFF2D8" />
                  </Icon>
                  <Icon to={`https://wa.me/6285727520642`}>
                    <BsWhatsapp color="#FFF2D8" />
                  </Icon>
                </div>
              </div>
            </Col>
            <Col md={6} className="logo d-flex justify-content-end">
              <img src={pictProfile} className="img-fluid " />
            </Col>
          </React.Fragment>
        ) : (
          <React.Fragment>
            <Col md={6} className="logo my-5">
              <img src={pictProfile} className="img-fluid " />
            </Col>
            <Col md={6} className="header-content">
              <div className="desc">
                <TypeAnimation
                  sequence={[
                    "Senat Mahasiswa Fakultas", // Types 'One'
                    1000, // Waits 1s
                    "Fakultas Ekonomika dan Bisnis",
                    2000, // Waits
                    "Universitas Kristen Satya Wacana",
                    2000, // Waits
                  ]}
                  wrapper="h1"
                  cursor={true}
                  repeat={Infinity}
                  className="font-viga text-start"
                  style={{
                    fontSize: "2em",
                    display: "inline-block",
                  }}
                />
                <p className="font-voces text-justify">
                  Untuk mengikuti perkembangan terbaru dan berinteraksi dengan
                  Senat Mahasiswa Fakultas Ekonomi dan Bisnis Universitas
                  Kristen Satya Wacana dalam hal Organization Relations,
                  Project, dan Media Partnership, Anda dapat menghubungi kami
                  melalui kontak berikut: 081384805665.
                </p>
              </div>
              <div className="contact-us">
                <h2 className="font-viga text-start">Contact Us</h2>
                <div className="d-flex">
                  <Icon to={"mailto:smf.feb@student.uksw.edu"}>
                    <MdEmail color="#FFF2D8" size={20} />
                  </Icon>
                  <Icon
                    to={
                      "https://www.linkedin.com/company/senat-mahasiswa-fakultas-feb-uksw/"
                    }
                  >
                    <BsLinkedin color="#FFF2D8" />
                  </Icon>
                  <Icon to={"https://instagram.com/smffebuksw"}>
                    <BsInstagram color="#FFF2D8" />
                  </Icon>
                  <Icon to={`https://wa.me/6285727520642`}>
                    <BsWhatsapp color="#FFF2D8" />
                  </Icon>
                </div>
              </div>
            </Col>
          </React.Fragment>
        )}
      </Row>
    </section>
  );
};

export default Header;
