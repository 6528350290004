import React, { useEffect, useMemo, useRef, useState } from "react";
import smf from "../../../../../../assets/IMG_4288.PNG";
import { useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import "react-quill/dist/quill.snow.css";
import axios from "axios";
import ReactQuill from "react-quill";
import { ToastContainer, toast } from "react-toastify";
import { Form } from "react-bootstrap";
import {
  handleLoading,
  setDataKastratById,
  updateDataKastrat,
} from "../../../../../../Config/redux/action";
import Loading from "../../../../Loading/Loading";
import { AiFillEdit, AiOutlineCheck } from "react-icons/ai";

const KastratDetailMainLeft = () => {
  // Data
  const dispatch = useDispatch();
  const dataById = useSelector((state) => state.dataById);

  const [data, setData] = useState({
    author: dataById.kastrat_author,
    title: dataById.kastrat_title,
    subject: dataById.kastrat_subject,
  });
  const [desc, setDesc] = useState(dataById.kastrat_desc);

  const loading = useSelector((state) => state.loading);

  const params = useParams();
  const idDetail = params.id;

  useEffect(() => {
    dispatch(handleLoading(true));

    dispatch(setDataKastratById(idDetail));
  }, [dispatch, idDetail]);

  useEffect(() => {
    setData({
      author: dataById.kastrat_author,
      title: dataById.kastrat_title,
      subject: dataById.kastrat_subject,
    });
    setDesc(dataById.kastrat_desc);
    localStorage.setItem("title", dataById.kastrat_title);
  }, [dataById]);

  // End of Data

  // Form
  const [form, setForm] = useState({
    author: false,
    title: false,
    subject: false,
    desc: false,
  });

  const handleChange = (e) => {
    setData({
      ...data,
      [e.target.name]: e.target.value,
    });
  };
  // End of Form

  // Quill Text Editor
  const quillRef = useRef();

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const quillObj = quillRef?.current?.getEditor();
      const range = quillObj?.getSelection();
      if (/^image\//.test(file.type)) {
        const res = await axios
          .post(
            `${process.env.REACT_APP_API_URL}/asset`,
            {
              asset_image: file,
              asset_title: localStorage.getItem("title"),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .catch((err) => {
            toast.error(err.response.data.message);
          });
        const data = res.data;
        quillObj.editor.insertEmbed(range.index, "image", data.secure_url);
      } else {
        console.warn("You could only upload images.");
      }
    };
  };
  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: [] }],
          [{ font: [] }],
          [{ align: ["right", "center", "justify"] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          [{ color: ["red", "#785412"] }],
          [{ background: ["red", "#785412"] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );
  // End of Quill Text Editor

  const date = new Date();
  const handleUpdate = async (e) => {
    e.preventDefault();
    dispatch(updateDataKastrat(idDetail, data, desc, date));
    setForm({
      author: false,
      title: false,
      subject: false,
      desc: false,
    });
    localStorage.removeItem("title");
  };

  return (
    <React.Fragment>
      {dataById.length === 0 ? (
        <Loading />
      ) : (
        <React.Fragment>
          <ToastContainer />
          <div className="kastrat-detail-main-left container pb-5">
            <div className="header d-flex align-items-center p-1 px-2">
              <img src={smf} alt="nav-logo" className="img-fluid img-detail" />
              <h6 className="m-0" style={{ fontWeight: 200 }}>
                Published in <span style={{ fontWeight: 800 }}>SMF Times</span>
              </h6>
            </div>
            {[dataById].map((item, index) => {
              const source = item.kastrat_title;
              const modifSrc = source?.replace(/_/g, "?");
              return (
                <div className="content mt-5" key={item.id}>
                  <div className="uploader d-flex align-items-center justify-content-between">
                    <div className="author-detail d-flex align-items-center">
                      <div className="author-img d-flex align-items-center">
                        <img src={smf} alt="author" className="img-fluid" />
                      </div>
                      <div className="author-name d-flex flex-column text-start ms-3">
                        <div className="d-flex align-items-center">
                          {form.author ? (
                            <React.Fragment>
                              <Form.Group>
                                <Form.Control
                                  type="text"
                                  name="author"
                                  value={data.author}
                                  onChange={handleChange}
                                />
                              </Form.Group>
                              <AiOutlineCheck
                                className="icon-edit ms-3"
                                onClick={handleUpdate}
                                size={20}
                              />
                            </React.Fragment>
                          ) : (
                            <React.Fragment>
                              <h6 className="m-0">{item.kastrat_author}</h6>
                              <AiFillEdit
                                className="icon-edit ms-3"
                                size={20}
                                onClick={() =>
                                  setForm({ author: !form.author })
                                }
                              />
                            </React.Fragment>
                          )}
                        </div>
                        <p className="m-0 detail-date">{item.created_at}</p>
                      </div>
                    </div>
                    <i className="fa-solid fa-link"></i>
                  </div>
                  <div className="title mt-4 d-flex justify-content-between">
                    <h3 className="m-0 text-start">{modifSrc}</h3>
                  </div>
                  <div className="thumb mt-4">
                    <img
                      src={`${process.env.REACT_APP_API_URL}/uploads/kastrat/${item.kastrat_title}/${item.kastrat_image}`}
                      alt="thumb"
                      className="img-fluid"
                    />
                  </div>
                  <div className="subject mt-4 d-flex justify-content-between">
                    {form.subject ? (
                      <React.Fragment>
                        <Form.Group>
                          <Form.Control
                            type="text"
                            style={{ width: "20rem" }}
                            name="subject"
                            value={data.subject}
                            onChange={handleChange}
                          />
                        </Form.Group>
                        <AiOutlineCheck
                          className="icon-edit"
                          size={20}
                          onClick={handleUpdate}
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <h5 className="text-start">{item.kastrat_subject}</h5>
                        <AiFillEdit
                          className="icon-edit"
                          size={20}
                          onClick={() => setForm({ subject: !form.subject })}
                        />
                      </React.Fragment>
                    )}
                  </div>
                  <div className="description mt-4">
                    {form.desc ? (
                      <React.Fragment>
                        <div className="d-flex justify-content-end mb-3">
                          <AiOutlineCheck
                            className="icon-edit"
                            size={20}
                            onClick={handleUpdate}
                          />
                        </div>
                        <ReactQuill
                          theme="snow"
                          value={desc}
                          onChange={setDesc}
                          ref={quillRef}
                          modules={modules}
                          bounds={".app"}
                          formats={[
                            "header",
                            "bold",
                            "italic",
                            "underline",
                            "strike",
                            "blockquote",
                            "list",
                            "bullet",
                            "link",
                            "color",
                            "image",
                            "background",
                            "align",
                            "size",
                            "font",
                          ]}
                          style={{ height: "300px" }}
                          placeholder="Write some description here..."
                          className="mb-5"
                        />
                      </React.Fragment>
                    ) : (
                      <React.Fragment>
                        <div className="d-flex justify-content-end mb-3">
                          <AiFillEdit
                            size={20}
                            className="icon-edit"
                            onClick={() => setForm({ desc: !form.desc })}
                          />
                        </div>

                        <div className="quill">
                          <div
                            className="ql-editor m-0 p-0"
                            dangerouslySetInnerHTML={{
                              __html: item.kastrat_desc,
                            }}
                          ></div>
                        </div>
                      </React.Fragment>
                    )}
                  </div>
                </div>
              );
            })}
          </div>
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default KastratDetailMainLeft;
