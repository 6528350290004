import React, { forwardRef, useEffect, useState } from "react";
import { motion } from "framer-motion";
import Container from "react-bootstrap/Container";
import Nav from "react-bootstrap/Nav";
import Navbar from "react-bootstrap/Navbar";
import { Link, Outlet, useNavigate, useParams } from "react-router-dom";
import smf from "../../../../../assets/IMG_4288.PNG";
import { Dropdown } from "react-bootstrap";
import KastratFooter from "../../KastratFooter/KastratFooter";
import loadings from "../../../../../assets/Infinity.svg";
import { useDispatch, useSelector } from "react-redux";
import {
  handleLoading,
  setDataKastrat,
} from "../../../../../Config/redux/action";
import { BsInstagram, BsLinkedin, BsSearch } from "react-icons/bs";
import { Cookies } from "react-cookie";

function KastratNav() {
  const [expanded, setExpanded] = useState(false);
  const CustomToggle = forwardRef(({ children, onClick }, ref) => (
    <div
      href=""
      ref={ref}
      onClick={(e) => {
        e.preventDefault();
        onClick(e);
      }}
    >
      {children}
    </div>
  ));
  const nav = useNavigate();
  const dispatch = useDispatch();
  const dataKastrat = useSelector((state) => state.data);
  const loading = useSelector((state) => state.loading);
  const [searchData, setSearchData] = useState();
  const [forceUpdate, setForceUpdate] = useState(false);
  const params = useParams();
  const cookies = new Cookies();

  const logout = () => {
    cookies.remove("token");
    nav("/");
  };

  const handleSetData = () => {
    setSearchData(dataKastrat);
    setForceUpdate(true);
  };

  const handleForceUpdate = () => {
    setForceUpdate(false);
  };

  useEffect(() => {
    if (!cookies.get("token")) {
      nav("/kastrat");
    }
  }, [cookies.get("token")]);

  useEffect(() => {
    dispatch(handleLoading(true));
    dispatch(setDataKastrat(searchData));
  }, [dispatch, searchData]);

  return (
    <React.Fragment>
      {params.id === undefined ? (
        <React.Fragment>
          <Navbar expand="lg">
            <Container className="kastrat-nav-container">
              <Navbar.Brand href="#home" className="brand">
                <Link to="/" className="text-decoration-none text-black">
                  <h1>SMF Times</h1>
                </Link>
              </Navbar.Brand>
              <Navbar.Toggle aria-controls="basic-navbar-nav">
                <i className="fa-solid fa-burger"></i>
              </Navbar.Toggle>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto kastrat-nav-link">
                  <Nav.Link className="d-flex justify-content-center align-items-center">
                    <BsSearch
                      className={`${expanded ? "mx-2" : ""}`}
                      onClick={() => setExpanded(!expanded)}
                    />
                    <motion.input
                      type="text"
                      className="form-control search-input"
                      placeholder="Search"
                      onChange={(e) => {
                        setSearchData(e.target.value);
                      }}
                      initial={false}
                      animate={{
                        width: expanded ? "300px" : "0",
                        padding: expanded ? "8px 12px" : "0",
                        opacity: expanded ? 1 : 0,
                      }}
                      transition={{ duration: 0.3 }}
                    />
                  </Nav.Link>
                  <Link
                    className="d-flex justify-content-center align-items-center p-3 text-decoration-none"
                    style={{ color: "rgba(0,0,0,0.55)" }}
                    to={"https://instagram.com/smffebuksw"}
                  >
                    <BsInstagram />
                  </Link>
                  <Link
                    className="d-flex justify-content-center align-items-center p-3 text-decoration-none"
                    style={{ color: "rgba(0,0,0,0.55)" }}
                    to={"https://linkedin.com/AllLinkSMFFEB"}
                  >
                    <BsLinkedin />
                  </Link>

                  <Nav.Link
                    href="#link"
                    className="d-flex justify-content-center align-items-center ms-2 me-2"
                  >
                    <div className="loged-in d-flex ms-3 align-items-center">
                      <div className="image-logedin-container">
                        <img
                          src={smf}
                          alt="img-log"
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div className="name-logedin-container ms-3 text-end">
                        <Dropdown align={"end"}>
                          <Dropdown.Toggle as={CustomToggle} className="p-0">
                            <h6 className="m-0">
                              SMF FEB{" "}
                              <i class="fa-solid fa-chevron-down icon-edit"></i>
                            </h6>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="drop-logout">
                            <Dropdown.Item
                              className="text-danger"
                              onClick={() => logout()}
                            >
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <p className="m-0">Admin</p>
                      </div>
                    </div>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>

          <Outlet
            context={[loading, dataKastrat, handleForceUpdate, forceUpdate]}
          />

          <KastratFooter />
        </React.Fragment>
      ) : (
        <React.Fragment>
          <Navbar
            expand="lg"
            className="kastrat-nav-detail-container"
            bg="gray"
          >
            <Container>
              <Navbar.Brand href="#home" className="brand">
                <Link
                  to="/kastrat-admin"
                  className="text-decoration-none text-black"
                >
                  <img
                    src={smf}
                    alt="nav-logo"
                    className="img-fluid img-detail"
                  />
                </Link>
              </Navbar.Brand>
              <Navbar.Collapse id="basic-navbar-nav">
                <Nav className="ms-auto kastrat-nav-link">
                  <Link
                    className="d-flex justify-content-center align-items-center p-3 text-decoration-none"
                    style={{ color: "rgba(0,0,0,0.55)" }}
                    to={"https://instagram.com/smffebuksw"}
                  >
                    <BsInstagram />
                  </Link>
                  <Link
                    className="d-flex justify-content-center align-items-center p-3 text-decoration-none"
                    style={{ color: "rgba(0,0,0,0.55)" }}
                    to={
                      "https://www.linkedin.com/company/senat-mahasiswa-fakultas-feb-uksw/"
                    }
                  >
                    <BsLinkedin />
                  </Link>
                  <Nav.Link
                    href="#link"
                    className="d-flex justify-content-center align-items-center ms-2 me-2"
                  >
                    <div className="loged-in d-flex ms-3 align-items-center">
                      <div className="image-logedin-container">
                        <img
                          src={smf}
                          alt="img-log"
                          className="img-fluid mx-auto"
                        />
                      </div>
                      <div className="name-logedin-container ms-3 text-end">
                        <Dropdown align={"end"}>
                          <Dropdown.Toggle as={CustomToggle} className="p-0">
                            <h6 className="m-0">
                              SMF FEB{" "}
                              <i class="fa-solid fa-chevron-down icon-edit"></i>
                            </h6>
                          </Dropdown.Toggle>

                          <Dropdown.Menu className="drop-logout">
                            <Dropdown.Item
                              className="text-danger"
                              onClick={() => logout()}
                            >
                              Logout
                            </Dropdown.Item>
                          </Dropdown.Menu>
                        </Dropdown>
                        <p className="m-0">Admin</p>
                      </div>
                    </div>
                  </Nav.Link>
                </Nav>
              </Navbar.Collapse>
            </Container>
          </Navbar>
          <Outlet />
        </React.Fragment>
      )}
    </React.Fragment>
  );
}

export default KastratNav;
