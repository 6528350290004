import React, { useEffect } from "react";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import logoSmf from "../../../assets/logo-cropped.png";
import { useDispatch, useSelector } from "react-redux";
import { logout, tokenEqual } from "../../../Config/redux/action";
import { Outlet, useLocation } from "react-router-dom";
import KastratFooter from "../Kastrat/KastratFooter/KastratFooter";
import "./NavProfile.css";
import { Cookies } from "react-cookie";

const NavProfile = () => {
  const cookies = new Cookies();
  const dispatch = useDispatch();
  const { login } = useSelector((state) => state);
  const location = useLocation();
  const token = cookies.get("token");

  const handleLogout = () => {
    dispatch(logout());
  };

  useEffect(() => {
    if (token) {
      dispatch(tokenEqual(token));
    }
  }, [dispatch, token]);

  return (
    <React.Fragment>
      <Navbar
        expand="lg"
        className="bg-body-tertiary font-viga fixed-top p-3 nav-profile"
      >
        <Container>
          <Navbar.Brand href={location.pathname == "/" ? "#home" : "/"}>
            <img
              src={logoSmf}
              alt="logo"
              className="img-fluid me-3"
              style={{
                width: "3rem",
              }}
            />
            SMF FEB UKSW
          </Navbar.Brand>
          <Navbar.Toggle aria-controls="basic-navbar-nav" />
          <Navbar.Collapse id="basic-navbar-nav">
            <Nav className="ms-auto">
              <Nav.Link href={location.pathname == "/" ? "#home" : "/#home"}>
                Home
              </Nav.Link>
              <Nav.Link
                href={location.pathname == "/" ? "#product" : "/#product"}
              >
                Product
              </Nav.Link>
              <Nav.Link href={location.pathname == "/" ? "#about" : "/#about"}>
                About Us
              </Nav.Link>

              {login.length !== 0 && (
                <Button
                  variant="danger"
                  className="rounded"
                  onClick={handleLogout}
                >
                  Logout
                </Button>
              )}
            </Nav>
          </Navbar.Collapse>
        </Container>
      </Navbar>

      <Container className={`out-of-navbar h-screen`}>
        <Outlet context={[login]} />
      </Container>
      <KastratFooter />
    </React.Fragment>
  );
};

export default NavProfile;
