import React from "react";
import "./Profile.css";
import { Button, Container, Nav, Navbar } from "react-bootstrap";
import Header from "./Header/Header";
import Product from "./Product/Product";
import AboutUs from "./AboutUs/AboutUs";
import { useOutletContext } from "react-router-dom";

const Profil = () => {
  const [login] = useOutletContext();
  return (
    <React.Fragment>
      <Container className="container-profile out-of-navbar">
        <Header />
        <Product loginInfo={login} />
        <AboutUs />
      </Container>
    </React.Fragment>
  );
};

export default Profil;
