import React, { useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import pickImage from "../../../assets/pick_image.jpg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { createSemagrams } from "../../../Config/redux/action/globalAction";
import loadings from "../../../assets/Infinity.svg";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";

const AddSemagrams = () => {
  const [formData, setFormData] = useState({
    title: "",
    desc: "",
  });
  const input = useRef(null);
  const [saveImage, setSaveImage] = useState(null);
  const [image, setImage] = useState(pickImage);
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // validation input image
  const { ref, ...rest } = register("image", {
    required: true,
    onChange: (e) => handleUploadChange(e),
  });
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state);

  const handleUploadChange = (e) => {
    const uploaded = e.target.files[0];
    setImage(URL.createObjectURL(uploaded));
    setSaveImage(uploaded);
  };

  const handleChangeForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmits = (e) => {
    const date = new Date();
    dispatch(createSemagrams(saveImage, formData, date));
    setFormData({
      title: "",
      desc: "",
    });
  };

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden"; // Menonaktifkan scroll saat loading aktif
    } else {
      document.body.style.overflow = "auto"; // Mengaktifkan scroll setelah loading selesai
    }
  }, [loading]);

  return (
    <>
      <ToastContainer
        style={{
          zIndex: 99999,
        }}
      />
      {loading && (
        <div
          className="loading-container d-flex align-items-center justify-content-center h-screen"
          style={{
            zIndex: 9999, // Setel z-index tinggi
            width: "100%",
            overflow: "hidden",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <img src={loadings} alt="loading" />

          <div
            className="position-absolute"
            style={{
              zIndex: -2, // Setel z-index yang lebih rendah daripada div loading
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          ></div>
        </div>
      )}
      <h2 className="font-viga text-start">Tambah Bidang</h2>
      <Form className="my-5" onSubmit={handleSubmit(handleSubmits)}>
        <Row className="d-flex align-items-center">
          <Col md={6}>
            <FloatingLabel
              controlId="floatingInput"
              label="Title"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Title"
                name="title"
                {...register("title", {
                  required: "Title is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.title && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Title Wajib Diisi
                </p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Description"
              className="mb-3"
            >
              {/* tetxt araea */}
              <Form.Control
                as="textarea"
                name="desc"
                placeholder="Subject"
                style={{ height: "25rem" }}
                {...register("desc", {
                  required: "Description is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.desc && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Description Wajib Diisi
                </p>
              )}
            </FloatingLabel>
          </Col>
          <Col md={6}>
            <div className="mx-auto" onClick={() => input.current.click()}>
              <div>
                <img src={image} className="img-thumbnail" alt="..." />
              </div>
              <div className="my-3" style={{ display: "none" }}>
                <input
                  className="form-control"
                  type="file"
                  id="formFile"
                  name="image"
                  {...rest}
                  ref={(e) => {
                    input.current = e;
                    ref(e);
                  }}
                />
              </div>
            </div>
            {errors.image && (
              <p
                className="text-danger text-start fst-italic fw-bold"
                style={{ fontSize: 13 }}
              >
                Gambar Wajib Diisi
              </p>
            )}
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-5">
          <Button variant="success" type="submit">
            Tambahkan
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddSemagrams;
