import React, { useRef, useState } from "react";
import { Button, Col, FloatingLabel, Form, Row } from "react-bootstrap";
import pickImage from "../../../assets/pick_image.jpg";
import { useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import loadings from "../../../assets/Infinity.svg";
import { useEffect } from "react";
import { ToastContainer } from "react-toastify";
import { useParams } from "react-router-dom";
import { createKegiatanDepartement } from "../../../Config/redux/action";
import "./AddKegiatanDepartement.css";

const AddKegiatanDepartement = () => {
  const { id, idDepartement } = useParams();
  const [formData, setFormData] = useState({
    id_departement: "",
    name: "",
    tanggal_mulai: "",
    tanggal_berakhir: "",
    link_daftar: "-",
    desc: "",
  });
  const {
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // multiple image
  const image = pickImage;
  const [saveImage, setSaveImage] = useState(null);
  const [selectedImage, setSelectedImage] = useState([]);
  const input = useRef(null);
  // end multiple image
  const dispatch = useDispatch();
  const { loading } = useSelector((state) => state);

  // validation input image
  const { ref, ...rest } = register("kegiatan_departement_image", {
    required: true,
    onChange: (e) => handleUploadChange(e),
  });

  const handleUploadChange = (e) => {
    let uploaded = e.target.files;
    if (uploaded) {
      const fileArray = Array.from(uploaded).map((file) =>
        URL.createObjectURL(file)
      );
      setSelectedImage((prevImages) => prevImages.concat(fileArray));
    }
    setSaveImage(uploaded);
  };

  const handleChangeForm = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  const handleSubmits = () => {
    const date = new Date();
    dispatch(createKegiatanDepartement(formData, date, id, saveImage));
    setFormData({
      id_departement: "",
      name: "",
      tanggal_mulai: "",
      tanggal_berakhir: "",
      link_daftar: "-",
      desc: "",
    });
  };

  useEffect(() => {
    if (loading) {
      document.body.style.overflow = "hidden"; // Menonaktifkan scroll saat loading aktif
    } else {
      document.body.style.overflow = "auto"; // Mengaktifkan scroll setelah loading selesai
    }
  }, [loading]);

  useEffect(() => {
    setFormData({
      ...formData,
      id_departement: idDepartement,
    });
  }, [idDepartement]);

  return (
    <>
      <ToastContainer
        style={{
          zIndex: 99999,
        }}
      />
      {loading && (
        <div
          className="loading-container d-flex align-items-center justify-content-center h-screen"
          style={{
            zIndex: 9999, // Setel z-index tinggi
            width: "100%",
            overflow: "hidden",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <img src={loadings} alt="loading" />

          <div
            className="position-absolute"
            style={{
              zIndex: -2, // Setel z-index yang lebih rendah daripada div loading
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          ></div>
        </div>
      )}
      <h2 className="font-viga text-start">Tambah Kegiatan Departement</h2>
      <Form className="my-5" onSubmit={handleSubmit(handleSubmits)}>
        <Row className="d-flex align-items-center">
          <Col md={6}>
            <FloatingLabel
              controlId="floatingInput"
              label="Name"
              className="mb-3"
            >
              <Form.Control
                type="text"
                placeholder="Title"
                name="name"
                {...register("name", {
                  required: "Name is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.name && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Name Wajib Diisi
                </p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Tanggal Mulai"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder="Tanggal Mulai"
                name="tanggal_mulai"
                {...register("tanggal_mulai", {
                  required: "Tanggal Mulai is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.tanggal_mulai && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Tanggal Mulai Wajib Diisi
                </p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Tanggal Berakhir"
              className="mb-3"
            >
              <Form.Control
                type="date"
                placeholder="Tanggal Berakhir"
                name="tanggal_berakhir"
                {...register("tanggal_berakhir", {
                  required: "Tanggal Berakhir is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.tanggal_berakhir && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Tanggal Berakhir Wajib Diisi
                </p>
              )}
            </FloatingLabel>
            <FloatingLabel
              controlId="floatingInput"
              label="Description"
              className="mb-3"
            >
              {/* tetxt araea */}
              <Form.Control
                as="textarea"
                name="desc"
                placeholder="Subject"
                style={{ height: "25rem" }}
                {...register("desc", {
                  required: "Description is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.desc && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Description Wajib Diisi
                </p>
              )}
            </FloatingLabel>
          </Col>
          <Col md={6}>
            <div className="mx-auto" onClick={() => input.current.click()}>
              {selectedImage.length > 0 ? (
                <div className="preview-container-kegiatan-departement overflow-x-hidden">
                  <Row>
                    {selectedImage.map((photo) => (
                      <Col md="4" key={photo}>
                        <img
                          src={photo}
                          className="img-thumbnail mb-2"
                          alt="..."
                        />
                      </Col>
                    ))}
                  </Row>
                </div>
              ) : (
                <img src={image} className="img-thumbnail" alt="..." />
              )}
              <div className="my-3" style={{ display: "none" }}>
                <input
                  onChange={handleUploadChange}
                  className="form-control"
                  type="file"
                  id="formFile"
                  name="kegiatan_departement_image[]"
                  {...rest}
                  ref={(e) => {
                    input.current = e;
                    ref(e);
                  }}
                  multiple
                />
              </div>
            </div>
            {errors.kegiatan_departement_image && (
              <p
                className="text-danger text-start fst-italic fw-bold"
                style={{ fontSize: 13 }}
              >
                Gambar Wajib Diisi
              </p>
            )}
            <FloatingLabel
              controlId="floatingInput"
              label="Link Daftar"
              className="my-3"
            >
              <Form.Control
                type="text"
                placeholder="Link Daftar"
                name="link_daftar"
                value={formData.link_daftar}
                {...register("link_daftar", {
                  required: "Link Daftar is required",
                  onChange: (e) => {
                    handleChangeForm(e);
                  },
                })}
              />
              {errors.link_daftar && (
                <p
                  className="text-danger text-start fst-italic fw-bold"
                  style={{ fontSize: 13 }}
                >
                  Link Daftar Wajib Diisi
                </p>
              )}
            </FloatingLabel>
          </Col>
        </Row>
        <div className="d-flex justify-content-end mt-5">
          <Button variant="success" type="submit">
            Tambahkan
          </Button>
        </div>
      </Form>
    </>
  );
};

export default AddKegiatanDepartement;
