import axios from "axios";
import { toast } from "react-toastify";
import Swal from "sweetalert2";
import { Cookies } from "react-cookie";

export const login = (data) => {
  return (dispatch) => {
    axios
      .post(`${process.env.REACT_APP_API_URL}/login`, data)
      .then((res) => {
        const cookies = new Cookies();
        cookies.set("token", res.data, {
          expires: new Date(Date.now() + 1000 * 60 * 60 * 24 * 7),
        });
        toast.success("Login Success");
        dispatch({ type: "SET_LOGIN", payload: res.data });

        setTimeout(() => {
          window.location.href = "/";
        }, 2000);
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
};

export const handleLoading = (value) => {
  return { type: "SET_LOADING", payload: value };
};

export const tokenEqual = (token) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((res) => {
        dispatch({ type: "SET_LOGIN", payload: res.data });
      })
      .catch((err) => {
        toast.error(err.message);
      });
  };
};

export const logout = () => {
  return (dispatch) => {
    const cookies = new Cookies();
    cookies.remove("token");
    dispatch({ type: "SET_LOGOUT" });
    window.location.href = "/";
  };
};

// Kastrat
export const setDataKastrat = (searchData = "") => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/kastrat?kastrat_title=${searchData}`,
        {
          headers: {
            "Content-Type": "application/json",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      dispatch({ type: "SET_DATA", payload: res.data });
      dispatch(handleLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const setDataKastratById = (id) => {
  return async (dispatch) => {
    dispatch({ type: "SET_LOADING", payload: true });
    const cookies = new Cookies();
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/kastrat/${id}`,
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      dispatch({ type: "SET_DATA_BY_ID", payload: res.data });
      dispatch({ type: "SET_LOADING", payload: false });
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteDataKastrat = (id) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      const res = await axios.delete(
        `${process.env.REACT_APP_API_URL}/kastrat/${id}`,
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      Swal.fire({
        icon: "success",
        title: "Data Berhasil Dihapus",
        showConfirmButton: false,
        timer: 1500,
      })
        .then((result) => {
          dispatch(setDataKastrat());
        })
        .catch((error) => {
          Swal.fire({
            icon: "error",
            title: "Oops...",
            text: "Something went wrong!",
            showConfirmButton: false,
            timer: 1500,
          });
        });
    } catch (err) {
      Swal.fire({
        icon: "error",
        title: "Oops...",
        text: "Something went wrong!",
        showConfirmButton: false,
        timer: 1500,
      });
      console.log(err);
    }
  };
};

export const addDataKastrat = (saveImage, formData, editorContent, date) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    dispatch(handleLoading(true));
    const timer = 2000;
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/kastrat`,
        {
          kastrat_author: "SMF FEB UKSW",
          kastrat_image: saveImage,
          kastrat_title: formData.title,
          kastrat_subject: formData.subject,
          kastrat_desc: editorContent,
          created_at: date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          updated_at: date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      toast.success(
        "Kastrat added successfully at " +
          date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        {
          autoClose: timer,
        }
      );

      setTimeout(() => {
        window.location.href = "/kastrat-admin";
        dispatch(handleLoading(false));
      }, timer);
    } catch (err) {
      console.log(err);
      toast.error(err.response.request.statusText, {
        autoClose: timer,
      });

      setTimeout(() => {
        dispatch(handleLoading(false));
      }, timer);
    }
  };
};

export const updateDataKastrat = (id, data, desc, date) => {
  return (dispatch) => {
    const cookies = new Cookies();
    axios
      .put(
        `${process.env.REACT_APP_API_URL}/kastrat/${id}`,
        {
          kastrat_author: data.author,
          kastrat_title: data.title,
          kastrat_subject: data.subject,
          kastrat_desc: desc,
          updated_at: date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      )
      .then((res) => {
        toast.success("Kastrat updated successfully");
        dispatch(setDataKastratById(id));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

// Katalog
export const setDataKatalog = () => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/kegiatan`)
      .then((res) => {
        dispatch({ type: "SET_KEGIATAN", payload: res.data });
        dispatch(handleLoading(false));
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const setDataKatalogById = (id) => {
  return (dispatch) => {
    axios
      .get(`${process.env.REACT_APP_API_URL}/kegiatan/${id}`)
      .then((res) => {
        dispatch({ type: "SET_KEGIATAN_BY_ID", payload: res.data });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const deleteDataKatalog = (id) => {
  return (dispatch) => {
    axios
      .delete(`${process.env.REACT_APP_API_URL}/kegiatan/${id}`)
      .then((res) => {
        Swal.fire({
          icon: "success",
          title: "Data Berhasil Dihapus",
          showConfirmButton: false,
          timer: 1500,
        })
          .then((result) => {
            dispatch(setDataKatalog());
          })
          .catch((error) => {
            Swal.fire({
              icon: "error",
              title: "Oops...",
              text: "Something went wrong!",
            });
          });
      })
      .catch((err) => {
        console.log(err);
      });
  };
};

export const addDataKatalog = (saveImage, title, editorContent, date) => {
  return (dispatch) => {
    axios
      .post(
        `${process.env.REACT_APP_API_URL}/kegiatan`,
        {
          kegiatan_author: "SMF FEB UKSW",
          kegiatan_image: saveImage,
          kegiatan_title: title,
          kegiatan_desc: editorContent,
          created_at: date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
          updated_at: date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        },
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      )
      .then((res) => {
        toast.success(
          "Katalog added successfully at " +
            date.toLocaleDateString("en-US", {
              year: "numeric",
              month: "short",
              day: "numeric",
            })
        );

        dispatch(setDataKatalog());
        setTimeout(() => {
          window.location.href = "/kegiatan-admin";
        }, 2000);
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

export const updateDataKatalog = (id, data, desc, date) => {
  return (dispatch) => {
    axios
      .put(`${process.env.REACT_APP_API_URL}/kegiatan/${id}`, {
        kegiatan_author: data.author,
        kegiatan_title: data.title,
        kegiatan_desc: desc,
        updated_at: date.toLocaleDateString("en-US", {
          year: "numeric",
          month: "short",
          day: "numeric",
        }),
      })
      .then((res) => {
        toast.success("Katalog updated successfully");
        dispatch(setDataKatalogById(id));
      })
      .catch((err) => {
        toast.error(err.response.data.message);
      });
  };
};

// Semagrams
export const getDataSemagrams = () => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const res = await axios.get(`${process.env.REACT_APP_API_URL}/semagrams`);
      dispatch({ type: "SET_SEMAGRAMS", payload: res.data });
      dispatch(handleLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDataSemagramsById = (id) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/semagrams/${id}`
      );
      dispatch({ type: "SET_SEMAGRAMS_BY_ID", payload: res.data });
      dispatch(handleLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDataSemagramsByIdSearchDepartement = (id, search = "") => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/semagrams/${id}?nama_departement=${search}`
      );
      dispatch({ type: "SET_SEMAGRAMS_BY_ID", payload: res.data });
      dispatch(handleLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createSemagrams = (saveImage, formData, date) => {
  const timer = 2000;
  return async (dispatch) => {
    dispatch(handleLoading(true));
    const cookies = new Cookies();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/semagrams`,
        {
          semagrams_image: saveImage,
          semagrams_title: formData.title,
          semagrams_desc: formData.desc,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //after toast ended, redirect to semagrams page
      toast.success(
        "Semagrams added successfully at " +
          date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        {
          autoClose: timer,
        }
      );

      setTimeout(() => {
        window.location.href = "/semagrams";
        dispatch(handleLoading(false));
      }, timer);
    } catch (err) {
      console.log(err);
    }
  };
};

export const updateSemagrams = (id, data, date) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/semagrams/${id}`,
        {
          semagrams_title: data.title,
          semagrams_desc: data.desc,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      toast.success("Semagrams updated successfully");
      dispatch(getDataSemagramsById(id));
    } catch (err) {
      console.log(err);
    }
  };
};

export const deleteSemagrams = (id) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      Swal.fire({
        icon: "info",
        title: "Apakah kamu yakin?",
        text: "Bidang yang dihapus menghapus semua data yang berkaitan dengan bidang tersebut.",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
        confirmButtonColor: "#07bc0c",
        cancelButtonColor: "#e74c3c",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${process.env.REACT_APP_API_URL}/semagrams/${id}`, {
              headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
              },
            })
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              }).then((result) => {
                dispatch(getDataSemagrams());
                window.location.href = "/semagrams";
              });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

// Departement
export const getDataDepartement = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/semagrams/departement`
      );
      dispatch({ type: "SET_DEPARTEMENT", payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataDepartementById = (id) => {
  return async (dispatch) => {
    dispatch(handleLoading(true));
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/departement/${id}`
      );
      dispatch({ type: "SET_DEPARTEMENT_BY_ID", payload: res.data });
      dispatch(handleLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};
export const getDataDepartementByIdSearchKegiatan = (id, search) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/departement/${id}?nama_kegiatan=${search}`
      );
      dispatch({ type: "SET_DEPARTEMENT_BY_ID", payload: res.data });
      dispatch(handleLoading(false));
    } catch (err) {
      console.log(err);
    }
  };
};

export const createDepartement = (saveImage, formData, date) => {
  const timer = 2000;
  return async (dispatch) => {
    dispatch(handleLoading(true));
    const cookies = new Cookies();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/departement`,
        {
          id_bidang: formData.id_bidang,
          departement_image: saveImage,
          departement_name: formData.title,
          departement_desc: formData.desc,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //after toast ended, redirect to semagrams page
      toast.success(
        "Departement added successfully at " +
          date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        {
          autoClose: timer,
        }
      );

      setTimeout(() => {
        window.location.href = "/semagrams/" + formData.id_bidang;
        dispatch(handleLoading(false));
      }, timer);
    } catch (err) {
      toast.error(err.response.statusText, {
        autoClose: timer,
      });

      setTimeout(() => {
        dispatch(handleLoading(false));
      }, timer);

      console.log(err);
    }
  };
};

export const updateDepartement = (id, data) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/departement/${id}`,
        {
          departement_name: data.name,
          departement_desc: data.desc,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      toast.success("Departement updated successfully");
      dispatch(getDataDepartementById(id));
    } catch (err) {
      toast.error(err.response.statusText);
      console.log(err);
    }
  };
};

export const deleteDepartement = (id, idSemagrams) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      Swal.fire({
        icon: "info",
        title: "Apakah kamu yakin?",
        text: "Departemen yang dihapus menghapus semua data yang berkaitan dengan departemen tersebut.",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
        confirmButtonColor: "#07bc0c",
        cancelButtonColor: "#e74c3c",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(`${process.env.REACT_APP_API_URL}/departement/${id}`, {
              headers: {
                Authorization: `Bearer ${cookies.get("token")}`,
              },
            })
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              })
                .then((result) => {
                  dispatch(getDataDepartement());
                  window.location.href = "/semagrams/" + idSemagrams;
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};

// Kegiatan Departement
export const getDataKegiatanDepartement = () => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/kegiatandepartement`
      );
      dispatch({ type: "SET_KEGIATAN_DEPARTEMENT", payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
};

export const getDataKegiatanDepartementById = (id) => {
  return async (dispatch) => {
    try {
      const res = await axios.get(
        `${process.env.REACT_APP_API_URL}/kegiatandepartement/${id}`
      );
      dispatch({ type: "SET_KEGIATAN_DEPARTEMENT_BY_ID", payload: res.data });
    } catch (err) {
      console.log(err);
    }
  };
};

export const createKegiatanDepartement = (formData, date, id_bidang, image) => {
  const timer = 2000;
  return async (dispatch) => {
    dispatch(handleLoading(true));
    const format = { year: "numeric", month: "short", day: "numeric" };
    const formatTanggalMulai = new Date(
      formData.tanggal_mulai
    ).toLocaleDateString("en-US", format);
    const formatTanggalBerakhir = new Date(
      formData.tanggal_berakhir
    ).toLocaleDateString("en-US", format);
    const cookies = new Cookies();
    try {
      const res = await axios.post(
        `${process.env.REACT_APP_API_URL}/kegiatandepartement`,
        {
          id_departement: formData.id_departement,
          kegiatan_departement_name: formData.name,
          kegiatan_departement_desc: formData.desc,
          tanggal_mulai: formatTanggalMulai,
          tanggal_berakhir: formatTanggalBerakhir,
          link_daftar: formData.link_daftar,
          img_name: image,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
            "Content-Type": "multipart/form-data",
          },
        }
      );
      //after toast ended, redirect to semagrams page
      toast.success(
        "Kegiatan Departement added successfully at " +
          date.toLocaleDateString("en-US", {
            year: "numeric",
            month: "short",
            day: "numeric",
          }),
        {
          autoClose: timer,
        }
      );

      setTimeout(() => {
        window.location.href =
          "/semagrams/" + id_bidang + "/" + formData.id_departement;
        dispatch(handleLoading(false));
      }, timer);
    } catch (err) {
      toast.error(err.response.statusText, {
        autoClose: timer,
      });

      setTimeout(() => {
        dispatch(handleLoading(false));
      }, timer);

      console.log(err);
    }
  };
};

export const updateKegiatanDepartement = (id, formData) => {
  return async (dispatch) => {
    const format = { year: "numeric", month: "short", day: "numeric" };
    const formatTanggalMulai = new Date(
      formData.tanggal_mulai
    ).toLocaleDateString("en-US", format);
    const formatTanggalBerakhir = new Date(
      formData.tanggal_berakhir
    ).toLocaleDateString("en-US", format);
    const cookies = new Cookies();
    try {
      const res = await axios.put(
        `${process.env.REACT_APP_API_URL}/kegiatandepartement/${id}`,
        {
          id_departement: formData.id_departement,
          kegiatan_departement_name: formData.kegiatan_departement_name,
          kegiatan_departement_desc: formData.kegiatan_departement_desc,
          tanggal_mulai: formatTanggalMulai,
          tanggal_berakhir: formatTanggalBerakhir,
          link_daftar: formData.link_daftar,
        },
        {
          headers: {
            Authorization: `Bearer ${cookies.get("token")}`,
          },
        }
      );
      toast.success("Kegiatan Departement updated successfully");
      dispatch(getDataKegiatanDepartementById(id));
    } catch (err) {
      toast.error(err.response.statusText);
      console.log(err);
    }
  };
};

export const deleteKegiatanDepartement = (id, idSemagrams, idDepartement) => {
  return async (dispatch) => {
    const cookies = new Cookies();
    try {
      Swal.fire({
        icon: "info",
        title: "Apakah kamu yakin?",
        text: "Kegiatan yang dihapus menghapus semua data yang berkaitan dengan kegiatan tersebut.",
        showConfirmButton: true,
        showCancelButton: true,
        confirmButtonText: "Ya, hapus!",
        cancelButtonText: "Batal",
        confirmButtonColor: "#07bc0c",
        cancelButtonColor: "#e74c3c",
      }).then((result) => {
        if (result.isConfirmed) {
          axios
            .delete(
              `${process.env.REACT_APP_API_URL}/kegiatandepartement/${id}`,
              {
                headers: {
                  Authorization: `Bearer ${cookies.get("token")}`,
                },
              }
            )
            .then((res) => {
              Swal.fire({
                icon: "success",
                title: "Data Berhasil Dihapus",
                showConfirmButton: false,
                timer: 1500,
              })
                .then((result) => {
                  dispatch(getDataKegiatanDepartement());
                  window.location.href =
                    "/semagrams/" + idSemagrams + "/" + idDepartement;
                })
                .catch((error) => {
                  Swal.fire({
                    icon: "error",
                    title: "Oops...",
                    text: "Something went wrong!",
                  });
                });
            })
            .catch((err) => {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                text: "Something went wrong!",
                showConfirmButton: false,
                timer: 1500,
              });
            });
        }
      });
    } catch (err) {
      console.log(err);
    }
  };
};
