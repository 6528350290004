import React from "react";
import "./AboutUs.css";

const AboutUs = () => {
  return (
    <section id="about">
      <div className="about-us my-5 d-flex flex-column align-items-center justify-content-center">
        <h2 className="font-viga text-white">About Us</h2>
        <p className="font-voces text-white text-justify">
          Kami adalah Senat Mahasiswa Fakultas Ekonomika dan Bisnis (SMF FEB) di
          Universitas Kristen Satya Wacana (UKSW). Sebagai wadah perwakilan
          mahasiswa FEB, kami berkomitmen untuk memajukan kualitas pendidikan,
          mengembangkan potensi mahasiswa, dan memfasilitasi interaksi yang
          positif antara mahasiswa, fakultas, dan pihak eksternal. Dengan
          semangat kolaborasi, kami aktif dalam berbagai kegiatan, proyek, dan
          media partnership untuk menciptakan lingkungan akademik yang
          berinovasi dan inklusif. Dapatkan informasi terbaru dan terhubung
          dengan kami melalui berbagai platform komunikasi kami!
        </p>
      </div>
    </section>
  );
};

export default AboutUs;
