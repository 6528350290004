import React from "react";
import loading from "../../../assets/Infinity.svg";

const Loading = () => {
  return (
    <div
      className="w-100"
      style={{
        height: "100vh",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
      }}
    >
      <img src={loading} alt="loading.." />
    </div>
  );
};

export default Loading;
