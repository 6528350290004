import React, { useEffect, useState } from "react";
import { Col, Container, Dropdown, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link } from "react-router-dom";
import {
  deleteDataKatalog,
  handleLoading,
  setDataKatalog,
} from "../../../../../Config/redux/action";
import loadings from "../../../../../assets/Infinity.svg";

const KegiatanMain = () => {
  const [focus, setFocus] = useState(false);

  const handleFocus = () => {
    setFocus(!focus);
  };

  const dispatch = useDispatch();
  const dataKegiatan = useSelector((state) => state.dataKegiatan);
  const loading = useSelector((state) => state.loading);

  useEffect(() => {
    dispatch(handleLoading(true));
    dispatch(setDataKatalog());
  }, [dispatch]);

  const handleDelete = (id) => {
    dispatch(deleteDataKatalog(id));
  };

  const handleSearch = (e) => {
    const filtered = dataKegiatan.filter((item) => {
      return item.kegiatan_title
        .toLowerCase()
        .includes(e.target.value.toLowerCase());
    });
    dispatch({ type: "SET_KEGIATAN", payload: filtered });
    if (e.target.value === "") {
      dispatch(setDataKatalog());
    }
  };

  return (
    <Container>
      <div className="kegiatan-content mt-5">
        <div className="header d-flex justify-content-end mb-2">
          <Link to={"add-kegiatan"}>
            <button className="btn-add-kastrat ">Add Kegiatan</button>
          </Link>
        </div>
        <div className="search-container d-flex justify-content-between align-items-center">
          <div
            className={`search-input d-flex align-items-center m-0 me-3 ${
              focus ? "focus" : "null"
            }`}
            onFocus={handleFocus}
            onBlur={handleFocus}
          >
            <input
              type="text"
              class="form-control"
              placeholder="Search"
              onChange={handleSearch}
            />
            <i className="fa-solid fa-magnifying-glass pe-3"></i>
          </div>
        </div>

        {loading ? (
          <div
            className="loading-container d-flex align-items-center justify-content-center"
            style={{ height: "100vh" }}
          >
            <img src={loadings} alt="loading" className="d-block mx-auto" />
          </div>
        ) : (
          <React.Fragment>
            {dataKegiatan.length === 0 ? (
              <div className="d-flex justify-content-center align-items-center">
                <h1 className="text-center">Data Kosong</h1>
              </div>
            ) : (
              <React.Fragment>
                <div className="kegiatan-highlight-container">
                  <Row>
                    {dataKegiatan.map((item, index) => {
                      const image = item.kegiatan_image.split(",");
                      if (index <= 3) {
                        return (
                          <Col md={3} key={item.id}>
                            <div className="d-flex justify-content-center">
                              <div className="book-container-highlight">
                                <div className="options d-flex justify-content-end mb-2">
                                  <Dropdown
                                    align="end"
                                    title="Dropdown end"
                                    id="dropdown-menu-align-end"
                                  >
                                    <Dropdown.Toggle
                                      variant="none"
                                      id="dropdown-basic"
                                    >
                                      <i className="fa-solid fa-ellipsis"></i>
                                    </Dropdown.Toggle>

                                    <Dropdown.Menu>
                                      <Dropdown.Item
                                        className="d-flex justify-content-start align-items-center"
                                        onClick={() => handleDelete(item.id)}
                                      >
                                        <i className="fa-solid fa-trash-can"></i>
                                        <p className="m-0 ms-2 fw-light">
                                          Delete
                                        </p>
                                      </Dropdown.Item>
                                    </Dropdown.Menu>
                                  </Dropdown>
                                </div>
                                <Link to={`kegiatan-detail/${item.id}`}>
                                  <img
                                    src={`${process.env.REACT_APP_API_URL}/uploads/kegiatan/${item.kegiatan_title}/${image[0]}`}
                                    alt="cover"
                                    className="img-fluid book-cover"
                                  />
                                  <p className="mt-2">{item.kegiatan_title}</p>
                                  <p>by {item.kegiatan_author}</p>
                                </Link>
                              </div>
                            </div>
                          </Col>
                        );
                      }
                    })}
                  </Row>
                </div>
                <div className="kegiatan-more-container">
                  {dataKegiatan.length < 5 ? null : (
                    <React.Fragment>
                      <div className="more p-3 m-2">
                        <h5 className="text-start">Read more from FEB UKSW</h5>
                      </div>
                      <Row>
                        {dataKegiatan.map((item, index) => {
                          const image = item.kegiatan_image.split(",");
                          if (index > 3) {
                            return (
                              <Col md={3} key={item.id}>
                                <div className="d-flex justify-content-center">
                                  <div className="book-container">
                                    <div className="options d-flex justify-content-end mb-2">
                                      <Dropdown
                                        align="end"
                                        title="Dropdown end"
                                        id="dropdown-menu-align-end"
                                      >
                                        <Dropdown.Toggle
                                          variant="none"
                                          id="dropdown-basic"
                                        >
                                          <i className="fa-solid fa-ellipsis"></i>
                                        </Dropdown.Toggle>

                                        <Dropdown.Menu>
                                          <Dropdown.Item
                                            className="d-flex justify-content-start align-items-center"
                                            onClick={() =>
                                              handleDelete(item.id)
                                            }
                                          >
                                            <i className="fa-solid fa-trash-can"></i>
                                            <p className="m-0 ms-2 ">Delete</p>
                                          </Dropdown.Item>
                                        </Dropdown.Menu>
                                      </Dropdown>
                                    </div>
                                    <img
                                      src={`${process.env.REACT_APP_API_URL}/uploads/kegiatan/${item.kegiatan_title}/${image[0]}`}
                                      alt="cover"
                                      className="img-fluid book-cover"
                                    />
                                    <p className="m-0 mt-2 book-title">
                                      {item.kegiatan_title}
                                    </p>
                                    <p className="book-desc">
                                      by {item.kegiatan_author}
                                    </p>
                                  </div>
                                </div>
                              </Col>
                            );
                          }
                        })}
                      </Row>
                    </React.Fragment>
                  )}
                </div>
              </React.Fragment>
            )}
          </React.Fragment>
        )}
      </div>
    </Container>
  );
};

export default KegiatanMain;
