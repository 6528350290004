import React, { useEffect } from "react";
import KastratDetailMainView from "../../../../components/Kastrat/Admin/KastratDetailMain/KastratDetailMainView";
import "../../Kastrat.css";
import { useNavigate } from "react-router-dom";
import KastratFooter from "../../../../components/Kastrat/KastratFooter/KastratFooter";
import { Cookies } from "react-cookie";

const KastratDetailView = () => {
  const nav = useNavigate();
  const cookies = new Cookies();
  useEffect(() => {
    if (!cookies.get("token")) {
      nav("/kastrat");
    }
  }, []);
  return (
    <React.Fragment>
      <KastratDetailMainView />
      <KastratFooter />
    </React.Fragment>
  );
};

export default KastratDetailView;
