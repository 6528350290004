import React, { useEffect } from "react";
import KegiatanDetail from "../../../../components/Katalog/Admin/KegiatanDetail/KegiatanDetail";
import { Cookies } from "react-cookie";
import { useNavigate } from "react-router-dom";

const KegiatanDetailView = () => {
  const nav = useNavigate();
  const cookies = new Cookies();

  useEffect(() => {
    if (!cookies.get("token")) {
      nav("/kegiatan");
    }
  }, []);

  return <KegiatanDetail />;
};

export default KegiatanDetailView;
