import React, { useEffect } from "react";
import { Button, Container } from "react-bootstrap";
import smf from "../../../../../assets/IMG_4288.PNG";
import { Link, useParams } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setDataKastrat } from "../../../../../Config/redux/action";
import Loading from "../../../Loading/Loading";
import { useState } from "react";

const KastratDetailMainRight = () => {
  const dispatch = useDispatch();
  const dataKastrat = useSelector((state) => state.data);
  const [shuffledData, setShuffledData] = useState([]);
  const { id } = useParams();

  useEffect(() => {
    dispatch(setDataKastrat());
  }, [dispatch]);

  const url = window.location.pathname;
  const isAdmin = url.includes("kastrat-admin");

  const shuffleArray = (array) => {
    const data = [...array];
    data.forEach((value, index) => {
      if (value.id !== id) {
        const randomIndex = Math.floor(Math.random() * (index + 1));
        [data[index], data[randomIndex]] = [data[randomIndex], data[index]];
      }
    });
    setShuffledData(data);
  };

  useEffect(() => {
    shuffleArray(dataKastrat);
  }, [dataKastrat]);

  return (
    <React.Fragment>
      {dataKastrat.length === 0 ? (
        <Loading />
      ) : (
        <div className={`kastrat-detail-main-right position-fixed`}>
          <Container className="pt-5">
            <Container>
              <div className="header">
                <div className="author-img d-flex align-items-center">
                  <img src={smf} alt="author" className="img-fluid" />
                </div>
                <div className="author-name text-start mt-3">
                  <h5>SMF FEB UKSW</h5>
                </div>
                <div className="author-interact d-flex justify-content-start">
                  <Link to={"https://instagram.com/smffebuksw"}>
                    <Button className="btn btn-success"> Follow </Button>
                  </Link>
                  <Link to="mailto:smf.feb@student.uksw.edu">
                    <Button className="btn btn-success ms-2">
                      {" "}
                      <i className="fa-solid fa-envelope"></i>{" "}
                    </Button>
                  </Link>
                </div>
              </div>

              <div className="content mt-4">
                <div className="content-title text-start">
                  <h6>More from SMF Times</h6>
                </div>

                {shuffledData.map((item, index) => {
                  const source = item.kastrat_title;
                  const modifSrc = source?.replace(/_/g, "?");
                  if (index <= 2) {
                    return (
                      <Link
                        to={
                          isAdmin
                            ? `/kastrat-admin/kastrat-detail/${item.id}`
                            : `/kastrat/kastrat-detail/${item.id}`
                        }
                        key={item.id}
                      >
                        <div className="content-detail-container">
                          <div className="content-main mt-3 d-flex align-items-center justify-content-between">
                            <div className="content-main-left ">
                              <h6 className="text-start">{modifSrc}</h6>
                              <div className="author-img d-flex align-items-center">
                                <img
                                  src={smf}
                                  alt="author"
                                  className="img-fluid"
                                />
                                <p className="m-0 ms-2">SMF UKSW</p>
                              </div>
                            </div>
                            <div className="content-main-right ms-2">
                              <img
                                src={`${process.env.REACT_APP_API_URL}/uploads/kastrat/${item.kastrat_title}/${item.kastrat_image}`}
                                alt="times"
                                style={{ width: "7.5rem", height: "5rem" }}
                              />
                            </div>
                          </div>
                        </div>
                      </Link>
                    );
                  }
                })}
              </div>
              <div className="footer mb-3">
                <Link className="ms-1 me-1">Help</Link>
                <Link className="ms-1 me-1">Status</Link>
                <Link
                  className="ms-1 me-1"
                  to={"https://salman-adhim.vercel.app/"}
                >
                  Developer
                </Link>
                <Link className="ms-1 me-1">Terms & Condition</Link>
                <Link className="ms-1 me-1">About</Link>
              </div>
            </Container>
          </Container>
        </div>
      )}
    </React.Fragment>
  );
};

export default KastratDetailMainRight;
