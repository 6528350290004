import React from "react";
import { Col, Container, Row } from "react-bootstrap";
import KastratDetailMainLeft from "./KastratDetailMainLeft/KastratDetailMainLeft";
import KastratDetailMainRight from "../../KastratDetailMain/KastratDetailMainRight/KastratDetailMainRight";
import { useParams } from "react-router-dom";
import { useEffect } from "react";

const KastratDetailMainView = () => {
  const { id } = useParams();

  useEffect(() => {
    // Ketika ID berubah, gulir halaman ke atas
    window.scrollTo(0, 0);
  }, [id]);
  return (
    <div className="kastrat-detail-main-container">
      <div className="d-flex">
        <Container>
          <Row>
            <Col md={8}>
              <KastratDetailMainLeft />
            </Col>
            <Col md={4}>
              <KastratDetailMainRight />
            </Col>
          </Row>
        </Container>
      </div>
    </div>
  );
};

export default KastratDetailMainView;
