import React from "react";
import "./Icon.css";
import { Link } from "react-router-dom";

const Icon = ({ to, children }) => {
  return (
    <Link to={to}>
      <div className="icon d-flex align-items-center justify-content-center mx-1">
        {children}
      </div>
    </Link>
  );
};

export default Icon;
