import React, { Suspense } from "react";
import "./App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "react-toastify/dist/ReactToastify.css";
import "react-quill/dist/quill.snow.css";
import NavProfile from "./app/components/NavProfile/NavProfile";
import Profile from "./app/view/Profile/Profile";
import AddSemagrams from "./app/components/AddSemagrams/AddSemagrams";
import Detail from "./app/view/Semagrams/Detail/Detail";
import { BrowserRouter, Route, Routes } from "react-router-dom";
import KegiatanNav from "./app/components/Katalog/KegiatanNav/KegiatanNav";
import KastratNavAdmin from "./app/components/Kastrat/Admin/KastratNav/KastratNav";
import KastratDetailViewAdmin from "./app/view/Kastrat/Admin/Details/KastratDetailView";
import AddKastrat from "./app/components/Kastrat/Admin/AddKastrat/AddKastrat";
import KegiatanNavAdmin from "./app/components/Katalog/Admin/KegiatanNav/KegiatanNav";
import KegiatanViewAdmin from "./app/view/Katalog/Admin/KegiatanView";
import KegiatanDetailViewAdmin from "./app/view/Katalog/Admin/Details/KegiatanDetailView";
import AddKegiatan from "./app/components/Katalog/Admin/AddKegiatan/AddKegiatan";
import { Provider } from "react-redux";
import { store } from "./Config";
import Loading from "./app/components/Loading/Loading";
import AddDepartement from "./app/components/AddDepartement/AddDepartement";
import AddKegiatanDepartement from "./app/components/AddKegiatanDepartement/AddKegiatanDepartement";
const Login = React.lazy(() => import("./app/view/Login/Login"));
const KastratView = React.lazy(() => import("./app/view/Kastrat/KastratView"));
const KastratDetailView = React.lazy(() =>
  import("./app/view/Kastrat/Details/KastratDetailView")
);
const KastratViewAdmin = React.lazy(() =>
  import("./app/view/Kastrat/Admin/KastratView")
);
const KegiatanView = React.lazy(() =>
  import("./app/view/Katalog/KegiatanView")
);
const KegiatanDetailView = React.lazy(() =>
  import("./app/view/Katalog/Details/KegiatanDetailView")
);
const Semagrams = React.lazy(() => import("./app/view/Semagrams/Semagrams"));
const SemagramsDetail = React.lazy(() =>
  import("./app/view/Semagrams//SemagramsDetail/SemagramsDetail")
);
const KegiatanSemaDetail = React.lazy(() =>
  import("./app/view/Semagrams//KegiatanSemaDetail/KegiatanSemaDetail")
);

function App() {
  return (
    <Provider store={store}>
      <div className="App">
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<NavProfile />}>
              <Route index element={<Profile />} />
              <Route
                path="semagrams"
                element={
                  <Suspense fallback={<Loading />}>
                    <Semagrams />
                  </Suspense>
                }
              />
              <Route
                path="semagrams/:id"
                element={
                  <Suspense fallback={<Loading />}>
                    <SemagramsDetail />
                  </Suspense>
                }
              />
              <Route path="bidang/add/" element={<AddSemagrams />} />
              <Route
                path="semagrams/departement/add/:id"
                element={<AddDepartement />}
              />
              <Route
                path="semagrams/departement/add/:id/:idDepartement"
                element={<AddKegiatanDepartement />}
              />
              <Route
                path="semagrams/:id/:idDepartement"
                element={
                  <Suspense fallback={<Loading />}>
                    <KegiatanSemaDetail />
                  </Suspense>
                }
              />
              <Route
                path="semagrams/:id/:idDepartement/:idKegiatanDepartement"
                element={<Detail />}
              />
            </Route>

            {/* Login */}
            <Route
              path="/login"
              element={
                <Suspense fallback={<Loading />}>
                  <Login />
                </Suspense>
              }
            />

            {/* Kastrat */}
            <Route
              path="/kastrat"
              element={
                <Suspense fallback={<Loading />}>
                  <KastratView />
                </Suspense>
              }
            />
            <Route
              path="/kastrat/kastrat-detail/:id"
              element={
                <Suspense fallback={<Loading />}>
                  <KastratDetailView />
                </Suspense>
              }
            />

            {/* Kegiatan */}
            <Route path="/kegiatan" element={<KegiatanNav />}>
              <Route
                index
                element={
                  <Suspense fallback={<Loading />}>
                    <KegiatanView />
                  </Suspense>
                }
              />
              <Route
                path="kegiatan-detail/:id"
                element={
                  <Suspense fallback={<Loading />}>
                    <KegiatanDetailView />
                  </Suspense>
                }
              />
            </Route>

            {/* Admin */}
            <Route path="/kastrat-admin" element={<KastratNavAdmin />}>
              <Route
                index
                element={
                  <Suspense fallback={<Loading />}>
                    <KastratViewAdmin />
                  </Suspense>
                }
              />
              <Route path="add-kastrat" element={<AddKastrat />} />
              <Route
                path="kastrat-detail/:id"
                element={<KastratDetailViewAdmin />}
              />
            </Route>

            {/* Kegiatan Admin*/}
            <Route path="/kegiatan-admin" element={<KegiatanNavAdmin />}>
              <Route index element={<KegiatanViewAdmin />} />
              <Route
                path="kegiatan-detail/:id"
                element={<KegiatanDetailViewAdmin />}
              />
              <Route path="add-kegiatan" element={<AddKegiatan />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </div>
    </Provider>
  );
}

export default App;
