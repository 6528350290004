import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const initialState = {
  data: [],
  dataById: [],
  dataKegiatan: [],
  dataByKegiatanId: [],
  dataSemagrams: [],
  dataSemagramsById: [],
  dataDepartementById: [],
  dataKegiatanDepartementById: [],
  login: [],
  loading: false,
};

const reducer = (state = initialState, action) => {
  switch (action.type) {
    case "SET_DATA":
      return {
        ...state,
        data: action.payload,
      };
    case "SET_DATA_BY_ID":
      return {
        ...state,
        dataById: action.payload,
      };
    case "SET_KEGIATAN":
      return {
        ...state,
        dataKegiatan: action.payload,
      };
    case "SET_KEGIATAN_BY_ID":
      return {
        ...state,
        dataByKegiatanId: action.payload,
      };
    case "SET_SEMAGRAMS":
      return {
        ...state,
        dataSemagrams: action.payload,
      };
    case "SET_SEMAGRAMS_BY_ID":
      return {
        ...state,
        dataSemagramsById: action.payload,
      };
    case "SET_DEPARTEMENT_BY_ID":
      return {
        ...state,
        dataDepartementById: action.payload,
      };
    case "SET_KEGIATAN_DEPARTEMENT_BY_ID":
      return {
        ...state,
        dataKegiatanDepartementById: action.payload,
      };
    case "SET_LOGIN":
      return {
        ...state,
        login: action.payload,
      };
    case "SET_LOADING":
      return {
        ...state,
        loading: action.payload,
      };
    default:
      return state;
  }
};

const store = createStore(reducer, applyMiddleware(thunk));

export default store;
