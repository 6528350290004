import axios from "axios";
import React, { useEffect, useMemo, useRef, useState } from "react";
import { Col, Container, FloatingLabel, Form, Row } from "react-bootstrap";
import { useForm } from "react-hook-form";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { useDispatch, useSelector } from "react-redux";
import { useOutletContext } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import * as yup from "yup";
import { addDataKastrat } from "../../../../../Config/redux/action";
import pickImage from "../../../../../assets/pick_image.jpg";
import loadings from "../../../../../assets/Infinity.svg";

const AddKastrat = () => {
  const { loading } = useSelector((state) => state);
  const schema = yup.object().shape({
    image: yup.mixed().test("file", "You need to provide a file", (value) => {
      if (value.length > 0) {
        return true;
      }
      return false;
    }),
  });
  const {
    register,
    handleSubmit,
    setValue,
    watch,
    formState: { errors },
  } = useForm({ validationSchema: schema });
  const [formData, setFormData] = useState({
    title: "",
    subject: "",
  });

  const handleChange = (e) => {
    setFormData({ ...formData, [e.target.name]: e.target.value });
  };

  // Image Preview
  const [saveImage, setSaveImage] = useState(null);
  const [image, setImage] = useState(pickImage);

  function handleUploadChange(e) {
    let uploaded = e.target.files[0];
    setImage(URL.createObjectURL(uploaded));
    setSaveImage(uploaded);
  }

  const input = useRef(null);
  const { ref, ...rest } = register("image", {
    required: true,
    onChange: (e) => handleUploadChange(e),
  });
  // End Of Image Preview

  // Quill text editor
  const quillRef = useRef();

  const imageHandler = () => {
    const input = document.createElement("input");
    input.setAttribute("type", "file");
    input.setAttribute("accept", "image/*");
    input.click();

    input.onchange = async () => {
      const file = input.files[0];
      const quillObj = quillRef?.current?.getEditor();
      const range = quillObj?.getSelection();
      if (/^image\//.test(file.type)) {
        const res = await axios
          .post(
            `${process.env.REACT_APP_API_URL}/asset`,
            {
              asset_image: file,
              asset_title: localStorage.getItem("title"),
            },
            {
              headers: {
                "Content-Type": "multipart/form-data",
              },
            }
          )
          .catch((err) => {
            toast.error(err.response.data.message);
          });
        const data = res.data;
        quillObj.editor.insertEmbed(range.index, "image", data.secure_url);
      } else {
        console.warn("You could only upload images.");
      }
    };
  };

  const modules = useMemo(
    () => ({
      toolbar: {
        container: [
          [{ header: [1, 2, 3, 4, 5, 6, false] }],
          ["bold", "italic", "underline", "strike", "blockquote"],
          [{ size: [] }],
          [{ font: [] }],
          [{ align: ["right", "center", "justify"] }],
          [{ list: "ordered" }, { list: "bullet" }],
          ["link", "image"],
          [{ color: ["red", "#785412"] }],
          [{ background: ["red", "#785412"] }],
        ],
        handlers: {
          image: imageHandler,
        },
      },
    }),
    []
  );

  const formats = [
    "header",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "bullet",
    "link",
    "color",
    "image",
    "background",
    "align",
    "size",
    "font",
  ];

  useEffect(() => {
    register("quillContent", { required: true, minLength: 15 });
  }, [register]);

  const onEditorStateChange = (editorState) => {
    setValue("quillContent", editorState);
  };

  const editorContent = watch("quillContent");
  // End Of Quill text editor

  const date = new Date();

  const dispatch = useDispatch();

  const handleSubmits = async () => {
    dispatch(addDataKastrat(saveImage, formData, editorContent, date));
    localStorage.removeItem("title");
  };

  return (
    <React.Fragment>
      <ToastContainer
        style={{
          zIndex: 99999,
        }}
      />
      {loading && (
        <div
          className="loading-container d-flex align-items-center justify-content-center h-screen"
          style={{
            zIndex: 9999, // Setel z-index tinggi
            width: "100%",
            overflow: "hidden",
            position: "fixed",
            top: 0,
            left: 0,
          }}
        >
          <img src={loadings} alt="loading" />

          <div
            className="position-absolute"
            style={{
              zIndex: -2, // Setel z-index yang lebih rendah daripada div loading
              width: "100%",
              height: "100vh",
              backgroundColor: "rgba(0,0,0,0.5)",
            }}
          ></div>
        </div>
      )}
      <div className="add-kastrat-container position-relative">
        <Container>
          <div className="mt-5">
            <h1 className="mb-5">Add Kastrat</h1>
            <Form onSubmit={handleSubmit(handleSubmits)}>
              <Row className="d-flex align-items-center justify-content-center mb-3">
                <Col md="6">
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Title"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="Title"
                      value={formData.title}
                      {...register("title", {
                        required: "Title is required",
                        onChange: (e) => {
                          handleChange(e);
                          localStorage.setItem("title", e.target.value);
                        },
                      })}
                    />
                    {errors.title && (
                      <p
                        className="text-danger text-start fst-italic"
                        style={{ fontSize: 13 }}
                      >
                        {errors.title.message}
                      </p>
                    )}
                  </FloatingLabel>
                  <FloatingLabel
                    controlId="floatingInput"
                    label="Subject"
                    className="mb-3"
                  >
                    <Form.Control
                      type="text"
                      placeholder="subject"
                      value={formData.subject}
                      {...register("subject", {
                        required: "Subject is required",
                        onChange: (e) => handleChange(e),
                      })}
                    />
                    {errors.subject && (
                      <p
                        className="text-danger text-start fst-italic"
                        style={{ fontSize: 13 }}
                      >
                        {errors.subject.message}
                      </p>
                    )}
                  </FloatingLabel>
                </Col>

                <Col md="6">
                  <div
                    className="mx-auto"
                    onClick={() => input.current.click()}
                  >
                    <div>
                      <img src={image} className="img-thumbnail" alt="..." />
                    </div>
                    <div className="my-3" style={{ display: "none" }}>
                      <input
                        className="form-control"
                        type="file"
                        id="formFile"
                        name="image"
                        {...rest}
                        ref={(e) => {
                          input.current = e;
                          ref(e);
                        }}
                      />
                    </div>
                    {errors.image && (
                      <p
                        className="text-danger text-start fst-italic text-center"
                        style={{ fontSize: 13 }}
                      >
                        Image field are required
                      </p>
                    )}
                  </div>
                </Col>
              </Row>
              <ReactQuill
                className="quill"
                ref={quillRef}
                theme="snow"
                value={editorContent}
                onChange={onEditorStateChange}
                modules={modules}
                formats={formats}
                style={{ maxHeight: "800px", height: "500px" }}
                placeholder="Write some description here..."
              />
              {errors.quillContent && (
                <p
                  className="text-danger text-start fst-italic"
                  style={{ fontSize: 13 }}
                >
                  Description field are required
                </p>
              )}
              <div className="btn-submit d-flex justify-content-end">
                <button className="btn-add-kastrat" type="submit">
                  Add Kastrat
                </button>
              </div>
            </Form>
          </div>
        </Container>
      </div>
    </React.Fragment>
  );
};

export default AddKastrat;
